.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  background: #fcfcfc; /* #fcfcfc */
  width: 47px;
  height: 104px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}
.carousel .slick-prev {
  left: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.carousel .slick-next {
  right: 0;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
}
.custom-indicator {
  bottom: -65px;
}
.custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}
.custom-indicator li.slick-active {
  filter: grayscale(0%);
}
.multi__image:hover {
  transform: scale(1.1);
  transition: 500ms;
}

.carousel h1 {
  margin: 15px 0;
  font-size: clamp(1rem, 0.1867rem + 3.6145vw, 2.5rem);
  color: #333;

  display: grid;
  grid-template-columns: minmax(50px, 1fr) auto minmax(50px, 1fr);
  align-items: center;
  text-align: center;
  gap: 35px;
}
.carousel h1::before,
.carousel h1::after {
  content: '';
  border-top: 6px double;
  border-color: #ff8000;
}

.heroBtn-container {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heroBtn {
  font-size: clamp(1rem, 0.7831rem + 0.9639vw, 1.4rem);
  padding: 1rem 4rem;
  border: none;
  background: #29e318;
  color: #fff;
  transition: 0.2s ease-out;
  border-radius: 3px;
}
.heroBtn:hover {
  background: #ffc500;
  transition: 0.2s ease-out;
  cursor: pointer;
  color: #000;
}
