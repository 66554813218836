.about-card {
  width: 85%;
  margin: 30px auto;

  overflow-x: hidden;
  padding: 3rem 2rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  background: #f1f1f1;
}

.about-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-image {
  width: 100%; /* 100% of it's parent container */
  height: 400px;
}
/* not used, just for reference */
.about-card:nth-child(even) img {
  order: 2;
}

.about-card h2 {
  font-size: clamp(1rem, 0.5663rem + 1.9277vw, 1.8rem);
  color: #555;
  margin-bottom: 0.8rem;
}

/* > direct child */
.about-card > div {
  padding: 2rem;
}

.about-card p {
  font-size: clamp(1rem, 0.8916rem + 0.4819vw, 1.2rem);
}

@media (max-width: 600px) {
  .about-card {
    display: block; /* make it one column */
  }
}

.about-btn {
  display: inline-block;
  margin-top: 1rem;
  font-size: clamp(1rem, 0.8916rem + 0.4819vw, 1.2rem);
  padding: 0.8rem 2rem;
  border: none;
  background: #f2745f;
  color: #fff;
  transition: 0.2s ease-out;
  border-radius: 3px;
}
.about-btn:hover {
  background: #ffc500;
  transition: 0.2s ease-out;
  cursor: pointer;
  color: #000;
}
