.map-container {
  width: 100%;
  height: 500px;
  margin-top: 3rem;
  overflow-x: hidden;
  background: #f1f1f1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  object-fit: contain;
}

.map-container h2 {
  color: #333;
  margin-bottom: 15px;
}
