/* Tab */
.menuscreen {
  width: 100%;
}

.menu-tabs {
  width: 100%;
  padding: 1rem 1rem;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.menu-tabs button {
  align-self: center;
  padding: 5px 10px;
  margin: 5px 5px;
  color: var(--secondary-white);

  font-family: 'Roboto Condensed', sans-serif;
  font-size: clamp(0.4rem, -0.1778rem + 2.4653vw, 1.4rem);
  letter-spacing: -1px;
  text-align: center;

  background: var(--nav-bg-color);
  cursor: pointer;
  border: none;
  border-radius: 0.2rem;

  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  transition: all 0.5s ease-out;
}
.menu-tabs button:hover {
  background: #ff8040;
  transform: scale(1.15);
}

/* 
.catTab {
  color: var(--secondary-white);

  font-family: 'Roboto Condensed', sans-serif;
  font-size: clamp(0.5rem, -0.025rem + 2.3333vw, 1.2rem);
  letter-spacing: -1px;
  text-align: center;

  background: var(--nav-bg-color);
  cursor: pointer;
}

.menuscreen button {
  border: none;
  border-radius: 20px;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}
.menuscreen button:hover {
  background: #ff8040;
  transform: scale(1.1);
}

.content-tabs {
  position: relative;
  flex-grow: 1;
}
.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}
 */
