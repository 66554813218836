* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --nav-bg-color: #203040;
  --main-bg-color: #feffd1;
  --secondary-white: #e0e0e0;
  --link-hover-color: #ff8000;
  --nav-top-color: #e0e0e0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  height: 100vh;

  font-family: 'Poppins', sans-serif;
  font-size: 1rem;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--main-bg-color);
}

/* 
https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
iphone6/7/8: 375x667
ipad: 1024x768
min-vw: 375
max-vw: 1024
1rem: 16px
font-size: clamp(1rem, 0.8916rem + 0.4819vw, 1.2rem);
font-size: clamp(1rem, 0.7831rem + 0.9639vw, 1.4rem);
font-size: clamp(1rem, 0.6747rem + 1.4458vw, 1.6rem);
font-size: clamp(1rem, 0.5663rem + 1.9277vw, 1.8rem);
font-size: clamp(1rem, 0.4578rem + 2.4096vw, 2rem);
font-size: clamp(1rem, 0.1867rem + 3.6145vw, 2.5rem);

font-size: clamp(0.8rem, 0.1066rem + 2.9584vw, 2rem);
font-size: clamp(0.7rem, 0.0644rem + 2.7119vw, 1.8rem);
font-size: clamp(0.6rem, 0.0222rem + 2.4653vw, 1.6rem);
font-size: clamp(0.5rem, -0.0200rem + 2.2188vw, 1.4rem);
font-size: clamp(0.4rem, -0.1778rem + 2.4653vw, 1.4rem);
*/

a {
  text-decoration: none;
}
a:hover {
  color: var(--link-hover-color); /* white */
}
/* .item-container a:hover {
  color: var(--link-hover-color);
} */

/* dropdown */
.dropdown {
  display: inline-block;
  position: relative;
  transition: all, 300ms, ease-out;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 120px;
  padding: 10px;
  z-index: 99;
  background-color: var(--nav-bg-color);
  margin: 0;
  margin-top: 0px;
  border-radius: 5px;

  font-size: 1rem;
}
.dropdown:hover .dropdown-content {
  display: block;

  z-index: 99;
}

/* Common */
h1 {
  font-size: 1.125rem;
  padding: 10px 0;
}
h2 {
  font-size: 1rem;
  padding: 10px 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
li {
  margin-top: 10px;
}
input,
select,
textarea,
button {
  padding: 10px;
  border-radius: 5px;
  border: 1px #a4a4a4 solid;
  font-size: 1rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  background-color: #f8f8f8;
  cursor: pointer;
}
input:hover,
select:hover,
textarea:hover,
button:hover {
  border: 1px #404040 solid;
}
button.primary {
  background-color: #f0c040;
}
button.secondary {
  background-color: #8fe66d;
}
button.block {
  width: 100%;
}
button.small {
  font-size: 0.75rem;
}
button.main-menu {
  width: 150px;
}
button:disabled {
  background-color: lightgray;
}
.prepTimeBtn {
  background-color: #f0c040;
  width: 110px;
  transition: all, 500ms, ease-in-out;
}
.prepTimeBtn:hover {
  transform: scale(1.15);
  border: 3px #5a5959 solid;
  background-color: #dda60f;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.row.center {
  justify-content: center;
}
.row.top {
  align-items: flex-start;
}
.col-1 {
  flex: 1 1 15rem;
  margin-left: 1rem;
}
.col-2 {
  flex: 2 1 30rem;
  margin: 1rem 0.5rem 1rem 1.5rem;
}
.col-3 {
  flex: 3 1 45rem;
}
.min-18 {
  min-width: 18rem;
}

/* Image */
img {
  border-radius: 5px;
}
img.small {
  max-width: 50px;
  width: 100%;

  vertical-align: middle;
  padding-right: 5px;
}
img.medium {
  max-width: 290px;
  width: 100%;
}
img.large {
  width: 100%;
}

/* Card */
.card {
  border: 1px #c0c0c0 solid;
  background-color: #f8f8f8;
  border-radius: 5px;
  margin: 10px;
}
.card-body {
  padding: 10px;
}
.card-body > * {
  margin-bottom: 5px;
}
.price {
  font-size: 1.25rem;
}
.pay-cash-note {
  height: 100px;
  margin: 30px auto;
  background-color: lightpink;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
}

/* Alert */
.loading {
  display: block !important;
}
.success {
  color: #20a020;
}
.danger {
  color: #a02020;
}
.alert {
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
}
.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}
.alert-danger {
  color: #a02020;
  background-color: #ffe0e0;
}
.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}
/* Form */
.form {
  max-width: 600px;
  margin: 0 auto;
}
.form > div {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.form label {
  margin: 10px 0;
}

/* Checkout Steps */
.checkout-steps > div {
  border-top: 3px #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 10px;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: #f08000;
  color: #f08000;
}
/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
}
.table tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}
.table td,
.table th {
  text-align: left;
  border: 1px solid #e4e4e4;
  padding: 5px;
}
.table button {
  margin: 0 2px;
}

/* added by Richard*/
.grid-col2 {
  margin: 50px 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 75px;
  grid-auto-flow: row;
}

.category-title {
  grid-column: 1 / 2;
  grid-row: span 1; /* span one row */
  align-self: flex-start;
}
.category-title h1 {
  padding-left: 5px;
  font-size: 2rem;
  color: #555;
}
.category-title h2 {
  padding-left: 5px;
  font-size: 1.5rem;
  color: #555;
}
.text-center {
  text-align: center;
}

.category-img-container {
  width: 100%;
  height: 100%;
  align-self: center;

  grid-column: 2 / 3;
  grid-row: 1 / 4;

  display: flex;
  justify-content: center;
  align-items: center;

  object-fit: contain;
  opacity: 1;

  margin-top: 50px;
}
.category-img {
  width: 90%; /* 90% of grid-row: 1 / 4 */
  height: auto; /* 90% of grid-column: 2 / 3 */
  border-radius: 5px;
  margin-bottom: 1rem;
}

#meal-in-box h1 {
  margin: 2rem 1rem 0 1rem;
  font-size: 2rem;
  color: rgb(94, 94, 212); /* #555; */

  display: grid;
  grid-template-columns: minmax(50px, 1fr) auto minmax(50px, 1fr);
  align-items: center;
  text-align: center;
  gap: 35px;
}
#meal-in-box h1:before,
#meal-in-box h1:after {
  content: '';
  border-top: 6px double;
  border-color: #ff8000;
}

/* parent: grid-col2 */
.item-container {
  grid-column: span 1; /* take one column */
  grid-row: span 1; /* take one row */

  padding: 10px 10px 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  background-color: rgb(233, 230, 230);
  border-radius: 5px;
}

.item-image {
  vertical-align: middle; /* align vertically with item-name */
  margin-right: 8px;
}
.item-filed1-field2 {
  width: 25%;
  align-self: flex-start;
  flex: 1 1 100px;
  padding: 0 10px;
}
.item-price {
  font-size: 1.25rem;
  align-self: center;
  flex: 0 0 30px;
  padding-right: 6px;
}
.item-price.through {
  text-decoration: line-through;
}

.radio-groups {
  width: 60%;
  padding: 0, 15px;
  flex: 1 1 175px;

  display: flex;
  justify-content: space-around;
}

.hot-flavor {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.radio-label {
  padding-right: 5px;
}

.item-qty {
  padding: 0 10px;
  flex: 0 0 30px;
  font-size: 1rem;
}
.item-button {
  cursor: pointer;
  background-color: rgb(243, 243, 108); /* #f0c040; */
  font-size: 0.75rem;
  flex: 0 0 30px;
  align-self: flex-end;
}
.item-button:hover {
  background-color: var(--link-hover-color);
}

@media screen and (max-width: 700px) {
  .grid-col2 {
    grid-template-columns: 1fr;
  }

  .category-title {
    grid-column: span 1;
    grid-row: span 1;
  }

  .category-img-container {
    grid-column: span 1;
    grid-row: span 1;
  }

  .item-container:nth-of-type(odd) {
    background-color: lightgray;
  }
  .item-container:nth-of-type(even) {
    background-color: darkgray;
  }
}

/* Socket.io */
/* list of users, and select a user */
.support-users {
  background: #f0f0f0;
  padding: 5px auto;
  margin: 5px auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .support-users button {
  width: 90px;
  border: none;
  text-align: left;
  margin-bottom: 15px;
  margin-left: 5px;
}
.support-users span {
  width: 45px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  margin-left: 1rem;
}
*/

.support-users input {
  width: 20px;
  height: 20px;
  margin: 10px;
}

/* .support-users input.order-checked {
    background-color: #ccc;
} */

/* .support-users li {
    margin: 0;
     background-color: #f8f8f8;  
    border-bottom: 1px #c0c0c0 solid;
}
.support-users li:hover {
    background-color: #f0f0f0;
} */
.selected-user {
  background-color: #c0c0c0;
}
span.offline-user {
  background-color: #c7c5c5;
}
span.online-user {
  background-color: #09c509;
}

/* message input and send */
.support-messages {
  padding: 10px;
}
.support-messages input {
  width: calc(100% - 5.6rem);
}
.support-messages ul {
  height: calc(100vh - 11rem);
  max-height: calc(100vh - 11rem);
  overflow: scroll;
}
.support-messages li {
  margin-bottom: 10px;
}
.support-messages .message-vh {
  max-height: 9rem;
}
