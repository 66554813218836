.modalBackground {
  top: 40px;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  width: 90%;
  height: 100%;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  overflow: scroll;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.mealinbox-img-container {
  width: 100%;
  /* height: 100%; */

  display: flex;
  justify-content: center;
  align-items: center;

  object-fit: contain;
  opacity: 1;

  margin-top: 10px;
}

.mealinbox-img {
  width: 80%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 2rem;
}
