.banner-container {
  width: 100%;
  position: sticky;

  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-container img {
  width: 100%;
  height: auto;
}
