.busi-container {
  margin-top: 3rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.busi-hours-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.busi-hours-logo img {
  width: 300px;
  height: 180px;
}

.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: clamp(1rem, 0.5663rem + 1.9277vw, 1.8rem);
  min-width: 360px; /* 360 for PWA, min-width: 400px; */

  color: #6ec1e4;
}

.content-table td {
  padding: 12px 15px;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.content-table tbody tr:nth-child(5) td:nth-child(2) {
  color: blueviolet;
}
.content-table tbody tr:nth-child(6) td:nth-child(2) {
  color: blueviolet;
}
