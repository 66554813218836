.bnav-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75px;

  display: flex;
  flex-direction: column;

  color: var(--secondary-white);
  background-color: var(--nav-bg-color);
}

.bnav-top {
  width: 100%;
  height: 45px;
  padding: 0 15px;

  font-family: 'Roboto Condensed', sans-serif;
  /* 1rem @360-px ~ 1.6rem @1024px */
  font-size: clamp(1rem, 0.6747rem + 1.4458vw, 1.6rem);
  letter-spacing: -1px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bnav-top a {
  color: var(--secondary-white);
}
.bnav-top a:hover {
  color: var(--link-hover-color);
}

.bnav-facebook {
  margin-top: 12px;
}
.bnav-home {
  margin-top: 12px;
}
.bnav-facebook a:hover,
.bnav-home a:hover {
  /* color: var(--link-hover-color); */ /* xWork */
  transform: scale(1.2);
}

.nav-copyright {
  width: 100%;
  height: 30px;

  border-top-style: solid;
  border-top-color: #c08000;
  border-top-width: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-copyright p {
  text-align: center;

  font-size: 1rem;
  color: var(--secondary-white);
}
