.sasayabistro-bar {
  width: 100%;
  height: 50px;
  background-color: var(--nav-bg-color);
}

.sasayabistro-context {
  padding: 0.5rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Roboto Condensed', sans-serif;
  /* 1rem @360-px ~ 1.6rem @1024px */
  font-size: clamp(1rem, 0.6747rem + 1.4458vw, 1.6rem);
  letter-spacing: 1px;
  text-align: center;
  color: var(--secondary-white);
}
.sasayabistro-context span {
  margin-left: 1rem;
}
