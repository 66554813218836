.nav-ehome-grid {
  position: sticky;
  width: 100%;
  height: 40px;
  top: 0;
  z-index: 99;

  padding-right: 5px;
  color: var(--nav-top-color);
  background-color: var(--nav-bg-color);

  font-family: 'Roboto Condensed', sans-serif;
  /* 1rem @360-px ~ 1.6rem @1024px */
  font-size: clamp(1rem, 0.6747rem + 1.4458vw, 1.6rem);
  letter-spacing: -1px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-home {
  padding-top: 5px;
  padding-right: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-ehome-grid > div:nth-child(1) {
  flex: 0 0 60px; /* grow, shrink, basis */
}
/* #nav-phone */
.nav-ehome-grid > div:nth-child(2) {
  flex: 1 0 150px; /* grow, shrink, basis */
}
.nav-ehome-grid > div:nth-child(3) {
  flex: 0 0 65px; /* grow, shrink, basis */
}
.nav-ehome-grid > div:nth-child(4) {
  flex: 0 0 75px; /* grow, shrink, basis */
}
.nav-ehome-grid > div:nth-child(5) {
  flex: 0 0 100px; /* grow, shrink, basis */
}
.nav-ehome-grid > div:nth-child(6) {
  flex: 0 0 100px; /* grow, shrink, basis */
}

/* .nav-spacer {
  flex: 1;
} */

/* #nav-phone {
  flex: 1 1 200px;
  display: flex;
  align-items: flex-end;
} */
#nav-phone span {
  padding-right: 6px;
}

.nav-ehome-grid a {
  color: var(--nav-top-color);
}
.nav-ehome-grid a:hover {
  color: var(--link-hover-color);
}

.badge {
  background-color: #f02020;
  color: #ffffff;
  border-radius: 50%;
  padding: 2px 7px;
  font-size: 1rem;
  margin-left: 2px;
}
