.intro-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 100%;
  background-color: var(--nav-bg-color);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.intro-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  line-height: 1.3;
}

.intro-container h1 {
  font-size: clamp(0.8rem, -0.1rem + 4vw, 2rem);
  font-family: 'Lemonada', cursive;
  color: #fff200;
}

.intro-items h2 {
  font-size: clamp(0.6rem, 0rem + 2.6667vw, 1.4rem);
  font-family: 'Lemonada', cursive; /* font-family: 'Caveat', cursive; */
  /* text-shadow: 0.1rem 0.1rem 0.3rem #333; */
  color: #fff;
}

.intro-items p {
  margin: 2rem 0.5rem;
  max-width: 700px;

  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: clamp(0.5rem, -0.025rem + 2.3333vw, 1.2rem);
  color: #ccc;
}

.intro-container button {
  margin-top: 0rem;
  font-size: clamp(0.6rem, 0rem + 2.6667vw, 1.4rem);
  font-weight: bold;

  padding: 12px 20px;
  border: none;
  background: #e31837;

  transition: 0.2s ease-out;
  border-radius: 5px;
}
.intro-container button a {
  color: var(--nav-bg-color);
}

.intro-container button:hover {
  background: #ffc500;
  transition: 0.2s ease-out;
  cursor: pointer;
  color: #000;
}
