.rt-banner {
  min-height: 100vh;

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../images/reservetable/background.jpg') center/cover no-repeat;
  background-attachment: fixed;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-bottom: 20px;
  margin-top: 100px;
}

.rt-banner h2 {
  padding-bottom: 40px;
  margin-bottom: 20px;

  font-family: 'Lemonada', cursive;
  font-size: clamp(1rem, 0.4578rem + 2.4096vw, 2rem);
  color: #eee;
}

.rt-card-container {
  padding: 1rem 3rem;
  border-radius: 5px;

  display: grid;
  grid-template-columns: minmax(50px, 1fr) 6fr minmax(50px, 1fr);
  align-items: center;
  text-align: center;
  gap: 35px;

  background: #eee;
  opacity: 0.9;
}

.rt-card-container::before,
.rt-card-container::after {
  content: '';
}

.rt-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.rt-form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rt-form-row label {
  flex: 0 0 100px;
}

.rt-form-row select,
.rt-form-row input,
.rt-form-row textarea {
  display: block;
  flex: 1 1 200px;

  margin: 15px 12px;
  padding: 5px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  outline: none;
  border: none;
  border-bottom: 1px solid #eee;
  font-weight: 300;
}
.datepicker {
  flex: 1 1 200px;
  /* margin-left: 2.65rem; */

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rt-form input[type='text'],
.rt-form input[type='number'],
.rt-select {
  color: #9a9a9a;
}

.rt-form [type='submit'] {
  color: #fff;
  background: #f2745f;
  padding: 12px 0;
  border-radius: 4px;
  cursor: pointer;

  width: 100%;
}
.rt-form [type='submit']:hover {
  opacity: 0.9;
}
