.online-order-banner-container {
  height: 100vh;
  margin-top: 30px;
  padding: 0px 15px;

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('/images/RHomePage/OnlineOrderBanner.jpg');
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.online-order-banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #f9f9f9;
}
.online-order-banner-content h1 {
  margin: 2rem 0;
  font-size: clamp(1rem, 0.1867rem + 3.6145vw, 2.5rem);
}
.online-order-banner-content p {
  margin: 0.8rem 0;
  font-size: clamp(1rem, 0.8916rem + 0.4819vw, 1.2rem);
}
