.special-container {
  width: 100%;
  min-height: 500px;

  background: linear-gradient(
      rgba(247, 232, 218, 0.1),
      rgba(247, 232, 218, 0.1)
    ),
    url('./SpecialOffer_v05.jpg') center/cover no-repeat;
  background-attachment: fixed;

  display: flex;
  align-items: center;
  justify-content: center;
}

.special-box {
  width: 40%;
  margin: 1rem 0rem;
  padding: 1rem 0.5rem;
  /* box-shadow: 5px 5px 5px rgba(245, 9, 245, 0.6); */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: rgb(248, 241, 248);
  border-radius: 15px;

  color: rgb(82, 81, 81); /* #f3f3ed #ffa500 */
  opacity: 0.75;
  text-align: center;
}

.special-box h1 {
  font-size: 1.25rem;
  color: rgb(240, 82, 82);
}
.special-box span {
  margin-left: 1rem;
}
